import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/authProvider";
import useStore from "../../../../hooks/useStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";

const AssignCategory = ({ setTab }) => {
  const { showAlert } = useAuth();
  const location = useLocation();

  const {
    employeeStore: { assignCategory, employeeDetails },
    companyStore: { companyDataJson },
  } = useStore();
  const companyData = location.state;

  const [categories, setCategories] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSave = () => {
    const body = JSON.stringify({
      empId: companyData?.id,
      categories,
    });
    assignCategory({ body, showAlert, employeeId: companyData?.id });
  };

  const allcategories = toJS(companyDataJson)?.assignCategory?.map((ele) => ({
    id: ele.catId,
    name: ele?.catIdAssignCategory?.name,
  }));

  useEffect(() => {
    setCategories(employeeDetails?.assignCategory?.map(({ catId }) => catId));
  }, [employeeDetails?.assignCategory]);
  useEffect(() => {
    setSelectAll(
      allcategories?.length > 0 && categories?.length === allcategories?.length
    );
  }, [categories, allcategories]);
  const handleAssignCategory = () => {
    handleSave();
    setTab("Sub Category");
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setCategories(allcategories.map((item) => item.id));
    } else {
      setCategories([]);
    }
    setSelectAll(e.target.checked);
  };
  return (
    <>
    <div className="table-responsive ">
        <table className="table align-middle table-bordered table-nowrap mb-0">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Category</th>
            <th>
              <input type="checkbox" style={{ marginRight: "5px", verticalAlign: "middle" }} checked={selectAll} onChange={handleSelectAll} /> 
              Assign</th>
          </tr>
        </thead>
        <tbody>
          {allcategories?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item?.name}</td>
              <td>
                <div className="form-check font-size-20 p-0">
                  <input
                    className="form-check-input m-0 float-none"
                    type="checkbox"
                    checked={categories?.includes(item?.id)}
                    id={index}
                    onChange={(e) => {
                      if (categories.includes(item?.id)) {
                        setCategories(
                          categories?.filter((id) => id !== item?.id)
                        );
                      }
                      if (e.target.checked) {
                        setCategories([...categories, item?.id]);
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3 ">
        <div className="col-md-12 text-center mt-3">
          <button onClick={handleSave} className="btn btn-primary px-4">
            Save
          </button>
          <button onClick={handleAssignCategory} className="btn btn-primary px-4 mx-4">
            Save & Next
          </button>
        </div>
        </div>
      </div>
    </>
  );
};

export default observer(AssignCategory);
