import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/authProvider";
import useStore from "../../../../hooks/useStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";

const AssignSubCategory = ({ setTab }) => {
  const { showAlert } = useAuth();
  const location = useLocation();
  const data = location.state;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const {
    employeeStore: { assignSubCategory, employeeDetails },
    companyStore: { companyDataJson },
  } = useStore();

  const handleSave = () => {
    const body = JSON.stringify({
      empId: data?.id,
      catId: selectedCategory,
      subcategories: subcategories,
    });

    assignSubCategory({ body, showAlert, setTab, employeeId: data?.id });
  };

  useEffect(() => {
    setSubcategories(
      employeeDetails?.assignSubCategory
        .filter(({ catId }) => catId === selectedCategory)
        .map(({ subCatId }) => subCatId)
    );
  }, [employeeDetails?.assignSubCategory, selectedCategory]);
  const allcategories = employeeDetails?.assignCategory?.map((ele) => ({
    id: ele.catId,
    name: ele?.catIdAssignCategory?.name,
  }));

  const allSubCategories = toJS(companyDataJson)?.assignSubCategory?.map(
    (ele) => ({
      id: ele.subCatId,
      catId: ele.catId,
      name: ele.subCatIdAssignSubCategory.name,
    })
  );

  useEffect(() => {
    const filteredSubCategories = allSubCategories?.filter((sub) =>
      selectedCategory ? sub.catId === selectedCategory : false
    );
    setSelectAll(
      filteredSubCategories?.length > 0 &&
      subcategories?.length === filteredSubCategories?.length
    );
    // eslint-disable-next-line no-use-before-define
  }, [subcategories, selectedCategory, allSubCategories]);

  const handleAssignCategory = () => {
    handleSave();
    setTab("KPI");
  };

  const handleSelectAll = (e) => {
    const filteredSubCategories = allSubCategories?.filter((sub) =>
      selectedCategory ? sub.catId === selectedCategory : false
    );
    if (e.target.checked) {
      setSubcategories(filteredSubCategories.map((item) => item.id));
    } else {
      setSubcategories([]);
    }
    setSelectAll(e.target.checked);
  };
  return (
    <div className="mt-10">

      <div className="row align-items-center mb-3">
        <div className="col-md-3">
          <label className="form-label">Category</label>
          <select
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {allcategories.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>


      <div className="table-responsive ">
        <table className="table align-middle table-bordered table-nowrap mb-0">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Sub Category</th>
              <th>
                <input
                  type="checkbox"
                  style={{ marginRight: "5px", verticalAlign: "middle" }}
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Assign</th>
            </tr>
          </thead>
          <tbody>
            {allSubCategories
              ?.filter((sub) =>
                selectedCategory ? sub.catId === selectedCategory : false
              )
              .map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td>
                    <div className="form-check font-size-20 p-0">
                      <input
                        className="form-check-input m-0 float-none"
                        type="checkbox"
                        checked={subcategories?.includes(item?.id)}
                        id={index}
                        onChange={(e) => {
                          if (subcategories?.includes(item?.id)) {
                            setSubcategories(
                              subcategories?.filter((id) => id !== item?.id)
                            );
                          }
                          if (e.target.checked) {
                            setSubcategories([...subcategories, item?.id]);
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3 ">
          <button onClick={handleSave} className="btn btn-primary px-4">
            Save
          </button>
          <button onClick={handleAssignCategory} className="btn btn-primary px-4 mx-4">
            Save & Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(AssignSubCategory);
