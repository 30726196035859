import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../utils/layout";
import { Link } from "react-router-dom";
import SubCategoryModal from "./SubCategoryModal";
import RemoveSubCategoryModal from "./RemoveSubCategoryModal";
import SubCategoryRow from "./SubCategoryRow";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import Select from "react-select";

const Categories = () => {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mode, setMode] = useState("");
  const [updateSubCategory, setUpdateSubCategory] = useState(null);
  const [fieldsPayload, setFieldsPayload] = useState({
    catIds: [], // Change from single catId to array of catIds
    subCatId: "",
  });
  const {
    dashboardStore: {
      subCategoryList,
      fetchSubCategories,
      searchSubCategory,
      filterSubCategories,
      fetchCategories,
      categoryList,
    },
    userStore: { userData },
  } = useStore();

  const handleNewCategory = () => {
    setShowCategoryModal(true);
    setMode("create");
  };

  const handleDeleteCategory = () => {
    setShowDeleteModal(true);
  };

  const handleEditCategory = (category) => {
    setShowCategoryModal(true);
    setMode("edit");
    setUpdateSubCategory(category);
  };

  const handleCloseNewCategoryModal = () => {
    setShowCategoryModal(false);
    setUpdateSubCategory(null);
  };

  const handleCloseDeleteCategoryModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    fetchSubCategories();
    fetchCategories();
  }, [fetchCategories, fetchSubCategories]);

  const filterationData = useCallback(() => {
    const categoryData = categoryList.map((cat) => ({
      id: cat.id,
      name: cat.name,
    }));

    return [
      { key: "catIds", data: categoryData, label: "Categories" }, // Adjust key for multiple selection
    ];
  }, [categoryList]);

  const filteredSubCategories = useCallback(() => {
    return subCategoryList.filter((subCat) =>
      fieldsPayload.catIds.length > 0
        ? fieldsPayload.catIds.map((e) => e.value).includes(subCat.catId)
        : true
    );
  }, [subCategoryList, fieldsPayload.catIds]);

  const handleCategoryChange = (e) => {
    setFieldsPayload((prev) => ({
      ...prev,
      catIds: e,
    }));
  };

  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Subcategories</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link
                        to={
                          userData.role === "owner"
                            ? "/admin/dashboard"
                            : "/company/dashboard"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">Subcategories</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Title */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2 ">
                    <div className="col-sm-8">
                      <div
                        className="search-filter align-items-center "
                        style={{ zIndex: 99 }}
                      >
                        {filterationData().map((filter) => (
                          <div key={filter.key} className="mb-2 w-25 ">
                            <Select
                              isMulti
                              isClearable
                              value={fieldsPayload.catIds}
                              classNamePrefix="react-select-custom"
                              options={filter.data.map((e) => ({
                                value: `${e.id}`,
                                label: e.name,
                              }))}
                              onChange={handleCategoryChange}
                            />
                          </div>
                        ))}

                        <div className="search-box d-inline-block">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              id="searchTableList"
                              placeholder="Search..."
                              value={searchSubCategory}
                              onChange={(e) =>
                                filterSubCategories(e.target.value)
                              }
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="text-sm-end">
                        {/* <button
                          type="button"
                          onClick={handleNewCategory}
                          className="btn btn-success waves-effect waves-light mb-2 me-2 addCustomers-modal"
                        >
                          <i className="mdi mdi-plus me-1"></i> New Subcategory
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {filteredSubCategories().length > 0 && (
                    <div className="table-responsive">
                      <table className="table align-middle table-bordered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Category</th>
                            <th>Name</th>
                            <th>Slug</th>
                            <th>Position</th>
                            <th>Create Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSubCategories().map((category, id) => (
                            <SubCategoryRow
                              handleDeleteCategory={handleDeleteCategory}
                              handleEditCategory={() =>
                                handleEditCategory(category)
                              }
                              category={category}
                              key={id}
                              index={id + 1}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubCategoryModal
        open={showCategoryModal}
        onClose={handleCloseNewCategoryModal}
        mode={mode}
        initialState={updateSubCategory}
      />
      <RemoveSubCategoryModal
        open={showDeleteModal}
        onClose={handleCloseDeleteCategoryModal}
      />
    </Layout>
  );
};

export default observer(Categories);
