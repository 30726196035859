import { action, computed, makeObservable, observable, toJS } from "mobx";
import fetchData from "../utils/fetchData";

export default class EmployeeStore {
  employees = [];
  employeeDetailsRaw = null;
  dashboardStatsRaw = 0;
  constructor() {
    makeObservable(this, {
      employees: observable,
      employeeDetailsRaw: observable,
      dashboardStatsRaw: observable,
      //actions
      fetchEmployee: action.bound,
      fetchEmployeeData: action.bound,
      assignCategory: action.bound,
      assignSubCategory: action.bound,
      assignKpi: action.bound,
      assignUnit: action.bound,
      fetchDashboardCount: action.bound,

      //computed
      employeesList: computed,
      employeeDetails: computed,
      dashboardStats: computed,
      reset: action.bound,
    });
  }

  reset() {
    this.employees = [];
    this.employeeDetailsRaw = null;
    this.dashboardStatsRaw = 0;
  }

  fetchEmployee({ signal }) {
    fetchData("company/companyEmployees", { signal })
      .then((res) => res.json())
      .then((data) => {
        this.employees = data.data.data;
      })
      .catch((err) => {
        console.error("[Error]", err);
      });
  }

  get employeesList() {
    return toJS(this.employees);
  }

  fetchEmployeeData({ signal, employeeId }) {
    fetchData(
      `company/getAllEmployeeData${
        employeeId ? `?employeeId=${employeeId}` : ""
      }`,
      {
        method: "GET",
        signal,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        this.employeeDetailsRaw = data.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  get employeeDetails() {
    return toJS(this.employeeDetailsRaw);
  }

  assignCategory({ body, setTab, showAlert, employeeId }) {
    const requestOptions = {
      method: "POST",
      body,
      redirect: "follow",
    };

    fetchData(
      `assign-employee/${
        this.employeeDetails?.assignCategory?.length !== 0
          ? "updatecategory"
          : "createCategory"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          // setTab("Sub Category");
          this.fetchEmployeeData({ employeeId });
        }
        showAlert(result.msg || result.message, result.status || false);
      })
      .catch((error) => console.error(error));
  }

  assignSubCategory({ body, setTab, showAlert, employeeId }) {
    const requestOptions = {
      method: "POST",
      body,
      redirect: "follow",
    };

    fetchData(
      `assign-employee/${
        this.employeeDetails?.assignSubCategory.length > 0
          ? "updateassignsubcategories"
          : "assignsubcategories"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          // setTab("KPI");
          this.fetchEmployeeData({ employeeId });
        }
        showAlert(result.msg || result.message, result.status || false);
      })
      .catch((error) => console.error(error));
  }

  assignKpi({ employeeId, showAlert, setStep, body }) {
    const requestOptions = {
      method: "POST",
      body,
      redirect: "follow",
    };
    fetchData(
      `assign-employee/${
        this.employeeDetails?.assignKpi?.length > 0
          ? "updateassignkpi"
          : "assignkpi"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          // setStep(2);
          this.fetchEmployeeData({ employeeId });
        }
        showAlert(result.msg || result.message, result.status || false);
      })
      .catch((error) => console.error(error));
  }

  assignUnit({ body, showAlert, navigate }) {
    const requestOptions = {
      method: "POST",
      body,
      redirect: "follow",
    };
    console.log(this.employeeDetails?.assignUnit);
    fetchData(
      `assign-employee/${
        this.employeeDetails && this.employeeDetails?.assignUnit?.length > 0
          ? "updateunit"
          : "createunit"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showAlert(result.msg || result.message, result.status || false);
        if (result.status) {
          navigate("/company/employee");
        }
      })
      .catch((error) => console.log("error", error));
  }

  async fetchDashboardCount({ signal }) {
    try {
      const res = await fetchData("company/companyDashboardCounts", { signal });
      const data = await res.json();
      this.dashboardStatsRaw = data.data;
    } catch (err) {
      console.error("[Error]", err);
    }
  }
  get dashboardStats() {
    return toJS(this.dashboardStatsRaw);
  }
}
